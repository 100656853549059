import React from "react"
import { injectIntl, Link, FormattedMessage } from "gatsby-plugin-intl"
import { useStaticQuery, graphql } from 'gatsby'
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

import trio from '../images/trio.png';

const AboutPage = ({ intl }) => {
	const { gallery, ekipa1, ekipa2, ekipa3, flota } = useStaticQuery(
		graphql`
		  query {
			gallery: allFile(
			  sort: { fields: name, order: DESC }
			  filter: { relativeDirectory: { eq: "about-gallery1" } }
			) {
			  edges {
				node {
				  id
				  name
				  childImageSharp {
					fluid(maxWidth: 300) {
					  ...GatsbyImageSharpFluid_withWebp_tracedSVG
					}
				  }
				}
			  }
			}
			ekipa1: allFile(
				sort: { fields: name, order: DESC }
				filter: { relativeDirectory: { eq: "about-gallery-ekipa1" } }
			  ) {
				edges {
				  node {
					id
					name
					childImageSharp {
					  fluid(maxWidth: 300) {
						...GatsbyImageSharpFluid_withWebp_tracedSVG
					  }
					}
				  }
				}
			}
			ekipa2: allFile(
				sort: { fields: name, order: DESC }
				filter: { relativeDirectory: { eq: "about-gallery-ekipa2" } }
			  ) {
				edges {
				  node {
					id
					name
					childImageSharp {
					  fluid(maxWidth: 300) {
						...GatsbyImageSharpFluid_withWebp_tracedSVG
					  }
					}
				  }
				}
			}
			ekipa3: allFile(
				sort: { fields: name, order: DESC }
				filter: { relativeDirectory: { eq: "about-gallery-ekipa3" } }
			  ) {
				edges {
				  node {
					id
					name
					childImageSharp {
					  fluid(maxWidth: 300) {
						...GatsbyImageSharpFluid_withWebp_tracedSVG
					  }
					}
				  }
				}
			}
			flota: allFile(
				sort: { fields: name, order: DESC }
				filter: { relativeDirectory: { eq: "about-gallery-flota" } }
			  ) {
				edges {
				  node {
					id
					name
					childImageSharp {
					  fluid(maxWidth: 300) {
						...GatsbyImageSharpFluid_withWebp_tracedSVG
					  }
					}
				  }
				}
			}
		  }
		`
	);

	return (
		<Layout pageName="about" siteTitle="about.page_title">
			<SEO
				lang={intl.locale}
				title={intl.formatMessage({ id: "title" })}
				description={intl.formatMessage({ id: "description" })}
				keywords={[`gatsby`, `application`, `react`]}
			/>
		
			<section className="wrapper container wrap narrow">
				<div className="solidBg overlap">
					{intl.locale=="pl"&&<div className="videoWrapper">
						<iframe width="560" height="315" src="https://www.youtube.com/embed/RTMCDD1xh9Y" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
					</div>	}
					{/* <h2 className="sectionTitle center">{intl.formatMessage({ id: "about.few_words" })}</h2> */}
					<p><FormattedMessage
						id="about.p1"
						defaultMessage={intl.formatMessage({ id: "about.p1" })}
						values={{b: chunks => <b>{chunks}</b>}}/>
					</p>
					<p><FormattedMessage
						id="about.p2"
						defaultMessage={intl.formatMessage({ id: "about.p2" })}
						values={{b: chunks => <b>{chunks}</b>}}/>
					</p>
					<div className="gallery gallery--2row">
						{gallery.edges.map((item, index) => { 
							const { node } = gallery.edges[index];
							return (
								<Img
									className="gallery-item"
									fluid={node.childImageSharp.fluid}
									key={node.id}
									alt={node.name.replace(/-/g, ' ').substring(2)}
								/>
							)
						})}
					</div>
				
					<h2 className="sectionTitle center" id="team">{intl.formatMessage({ id: "about.team" })}</h2>
					<p><strong>Julek - licencjonowany przewodnik po Maderze i właściciel DTT</strong> <br/>
					Polak mieszkający na wyspie od 2009 roku, a od 20 lat zajmujący się zawodowo turystyką. Interesuje się meteorologią, ornitologią, botaniką, nurkowaniem i fotografią.
					</p>
					
					<p><strong>Ania</strong><br/>
					zajmuje się marketingiem, mediami społecznościowymi i sprzedażą, nieoficjalnie wszystkim
					</p>
					
					<p><strong>Ola</strong><br/>
					Twój przewodnik podczas naszych ekscytujących wycieczek terenowych po Maderze, organizatorka eksploracji różnych wędrówek po wyspie. Masz pytania lub potrzebujesz więcej informacji? Ola jest osobą, od której możesz uzyskać wszelkie zapytania, niezależnie od tego, czy dotyczą szczegółów wycieczki, czy informacji ogólnych.<br/>
					Z sercem bijącym dla natury, duszą pragnącą wewnętrznego spokoju i pasją tworzenia znaczących relacji, Ola jest ucieleśnieniem naszego zaangażowania w dostarczanie niezapomnianych wrażeń w podróży po tej urzekającej wyspie!<br/>
<br/>
					</p>
					<img className="trip-map" style={{marginBottom: "2em"}} src={trio} />

					<h2 className="sectionTitle center" id="fleet">{intl.formatMessage({ id: "about.fleet" })}</h2>
					<p><FormattedMessage
						id="about.fleet-p1"
						defaultMessage={intl.formatMessage({ id: "about.fleet-p1" })}
						values={{b: chunks => <b>{chunks}</b>}}/>
					</p>
					<p><FormattedMessage
						id="about.fleet-p2"
						defaultMessage={intl.formatMessage({ id: "about.fleet-p2" })}
						values={{b: chunks => <b>{chunks}</b>}}/>
					</p>
					<p><FormattedMessage
						id="about.fleet-p3"
						defaultMessage={intl.formatMessage({ id: "about.fleet-p3" })}
						values={{b: chunks => <b>{chunks}</b>}}/>
					</p>
					<div className="gallery gallery--2row">
						{flota.edges.map((item, index) => { 
							const { node } = flota.edges[index];
							return (
								<Img
									className="gallery-item"
									fluid={node.childImageSharp.fluid}
									key={node.id}
									alt={node.name.replace(/-/g, ' ').substring(2)}
								/>
							)
						})}
					</div>
				</div>
			</section>
		</Layout>
	)
}

export default injectIntl(AboutPage)
